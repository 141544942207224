import {
  setIsLoadingEventsGraphsInProcess,
  setIsLoadingGraphsInProcess,
  setTsEventsGraph,
} from '../../app/state/insight';
import { store } from '../../app/store';
import { API_URL } from '../../consts';
import { InsightDataType } from '../../enums/InsightDataType';
import { generateApi, useApi } from '../../features/apiBuilder/useApiBuilder';
import { ApiCallType, HttpService } from '../../utils/http';

export enum GraphNameType {
  WATER_LEVEL = 'water_level',
  TEMPERATURE = 'temperature',
  EVENTS = 'events_graph',
}

export const baseHistory = { sensor: '', data: [], calibrationData: [], unit: '', axis_limits: { x: null, y: null } };

export const baseEventsHistory = {
  axis_labels: { x: '', y: '', title: '' },
  axis_limits: { x: null, y: null },
  data: [],
};

export interface HistoryType {
  sensor: string;
  data: any[];
  calibrationData: any[];
  unit: string;
  axis_labels?: { title: string | null; x: string | null; y: string | null };
  axis_limits: { x: { min: number; max: number } | null; y: { min: number; max: number } | null };
}

export const getHistory = (
  id: string,
  type: InsightDataType,
  data: any,
  languageStrings: any,
  graphName: GraphNameType,
  addNotification: (value: any) => void,
  setWaterLevel: (value: HistoryType) => void,
  setIsAplyActive?: React.Dispatch<React.SetStateAction<boolean>>,
  isDataLoaded?: boolean,
  openDataProcessingModal?: () => void,
  setApplyingStatus?: any,
  closeDataProcessingModal?: () => void,
  isMeasurement?: boolean,
) => {
  const url = isMeasurement
    ? `${API_URL}/v2/logical-set/${id}/insight/history`
    : `${API_URL}/v2/devices/${id}/insight/history`;
  const filterSetting =
    !!data.filter_interval && !!data.filter_averaging_method && !!data.filter_data_period ? data : {};
  const obj = {
    sensor: isMeasurement ? 'water_flow' : type,
    ...filterSetting,
  };
  openDataProcessingModal && openDataProcessingModal();
  setIsAplyActive && setIsAplyActive(false);
  (async () => {
    store.dispatch(setIsLoadingGraphsInProcess({ graphName: graphName, value: true }));
    try {
      const responseData = (await HttpService.post(url, obj)).data.payload;
      const result = { ...responseData };
      const preparedData = responseData.data.map((item: any) => ({ y: item.measurement, x: item.ts }));
      setWaterLevel({
        ...result,
        data: preparedData,
        calibrationData: responseData.calibration_periods || [],
      });
      setApplyingStatus && setApplyingStatus(2);
      closeDataProcessingModal && closeDataProcessingModal();
      store.dispatch(setIsLoadingGraphsInProcess({ graphName: graphName, value: false }));
    } catch (e) {
      setWaterLevel(baseHistory);
      //When global notifications will be fixed isDataLoaded should be removed @TODO ALEX
      isDataLoaded && addNotification({ message: languageStrings.unexpectedErrorHasOccurred, type: 'error' });
      closeDataProcessingModal && closeDataProcessingModal();
      store.dispatch(setIsLoadingGraphsInProcess({ graphName: graphName, value: false }));
      setApplyingStatus && setApplyingStatus(0);
    }
  })();
};

// new get history

export const useEntityHistory = ({
  id,
  body,
  handleSuccess,
  handleError,
  triggers,
  keywords = [],
  condition,
  isMeasurement,
}: {
  id: string;
  body: any;
  handleSuccess?: (data: any) => void;
  handleError?: (data: any) => void;
  triggers?: any[];
  keywords?: string[];
  condition?: boolean;

  isMeasurement?: boolean;
}) => {
  const url = isMeasurement
    ? `${API_URL}/v2/logical-set/${id}/insight/history`
    : `${API_URL}/v2/devices/${id}/insight/history`;

  const [data, , isLoading] = useApi({
    url,
    callType: ApiCallType.POST,
    body,
    keywords,
    defaultValue: baseHistory,
    triggers,
    condition,

    handleSuccess,
    handleError,
    transformResponse: (data) => data.payload,
  });

  return { data, isLoading };
};

export const getExportHistoryUrl = (
  id: string,
  type: InsightDataType,
  data: {
    filter_interval: string;
    filter_averaging_method: string;
    filter_data_period: string;
    ts_from: string;
    ts_to: string;
  },
  lang: string,
  setIsOpenExportModal: (val: boolean) => void,
) => {
  const url = `${API_URL}/v2/devices/${id}/insight/history/export?lang=${lang}`;

  const filterSetting =
    !!data.filter_interval && !!data.filter_averaging_method && !!data.filter_data_period ? data : {};
  const obj = {
    sensor: type,
    ...filterSetting,
  };
  (async () => {
    try {
      const responseData = (await HttpService.post(url, obj, { responseType: 'blob' })).data;
      const href = URL.createObjectURL(responseData);

      const link = document.createElement('a');
      link.href = href;
      link.target = '_blank';
      link.setAttribute('download', 'Export_sensor_history.xlsx');

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      setIsOpenExportModal(false);
    } catch (e) {}
  })();
};

export const baseEventsGraph = {
  data: [],
  axis_labels: { x: 'Date', y: 'Time', title: 'Events over time' },
  axis_limits: { x: null, y: null },
};
export interface EventsGraphType {
  data: any[];
  axis_labels: {
    x: string;
    y: string;
    title: string;
  };
  axis_limits: {
    x: {
      min: number;
      max: number;
    } | null;
    y: {
      min: number;
      max: number;
    } | null;
  };
}

export const getEventsGraph = (
  id: string,
  data: any,
  dispatch: React.Dispatch<React.SetStateAction<any>>,
  setEventsGraph: (value: EventsGraphType) => void,
  addNotification: (value: any) => void,
  isDataLoaded?: boolean,
  setIsApplyActive?: React.Dispatch<React.SetStateAction<boolean>>,
  setApplyingStatus?: any,
  openDataProcessingModal?: () => void,
  closeDataProcessingModal?: () => void,
) => {
  const url = `${API_URL}/v2/devices/${id}/insight/events_graph`;

  openDataProcessingModal && openDataProcessingModal();
  setIsApplyActive && setIsApplyActive(false);

  (async () => {
    dispatch(setIsLoadingEventsGraphsInProcess({ value: true }));
    try {
      const responseData = (await HttpService.post(url, data)).data.payload;

      setEventsGraph({ ...responseData });
      setApplyingStatus && setApplyingStatus(2);
      closeDataProcessingModal && closeDataProcessingModal();

      dispatch(setTsEventsGraph({ value: { ts_from: responseData.ts_from, ts_to: responseData.ts_to } }));
      dispatch(setIsLoadingEventsGraphsInProcess({ value: false }));
    } catch (e) {
      const error = e as any;

      isDataLoaded && addNotification({ message: error?.response?.data?.detail, type: 'error' });
      setEventsGraph(baseEventsGraph);
      setApplyingStatus && setApplyingStatus(0);
      closeDataProcessingModal && closeDataProcessingModal();

      dispatch(setIsLoadingEventsGraphsInProcess({ value: false }));
    }
  })();
};

// new get event graph

export const useEventsGraphHistory = ({
  id,
  body,
  handleSuccess,
  handleError,
  condition,
  triggers,
  keywords = [],
}: {
  id: string;
  body: any;
  handleSuccess?: (data: any) => void;
  handleError?: (data: any) => void;
  condition: boolean;
  triggers?: any[];
  keywords?: string[];
}) => {
  const url = `${API_URL}/v2/devices/${id}/insight/events_graph`;

  const [data, , isLoading] = useApi({
    url,
    callType: ApiCallType.POST,
    body,
    keywords,
    defaultValue: baseEventsHistory,
    triggers,
    condition,

    handleSuccess,
    handleError,
    transformResponse: (data) => data.payload,
  });

  return { data, isLoading };
};

export const getExportEventsUrl = (
  id: string,
  data: {
    filter_interval: string;
    filter_data_period: string;
    ts_from: string;
    ts_to: string;
  },
  lang: string,
  setIsOpenExportModal: (val: boolean) => void,
) => {
  const url = `${API_URL}/v2/devices/${id}/insight/events_graph/export?lang=${lang}`;

  const filterSetting =
    !!data.filter_interval && (!!data.filter_data_period || data.filter_data_period === null) ? data : {};

  (async () => {
    try {
      const responseData = (await HttpService.post(url, filterSetting, { responseType: 'blob' })).data;
      const href = URL.createObjectURL(responseData);

      const link = document.createElement('a');
      link.href = href;
      link.target = '_blank';
      link.setAttribute('download', 'Export_events_history.xlsx');

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      setIsOpenExportModal(false);
    } catch (e) {}
  })();
};

export const handleRegisterNewEvent = (data: any, setMessage: Function) => {
  const url = `${API_URL}/v2/devices/event`;
  (async () => {
    try {
      let responseData = (await HttpService.post(url, data)).data;
      setMessage(responseData.message);
    } catch (e) {}
  })();
};

export const useGetAvailableFiltersForGraph = ({
  id,
  handleSuccess,
  handleError,
  isMeasurement,
}: {
  id: string;
  handleSuccess: any;
  handleError: any;
  isMeasurement?: boolean;
}) => {
  const url = `${API_URL}/v2/devices/${id}/graph_filters`;
  // const url = isMeasurement ? `${API_URL}/v2/devices/graph_filters` : `${API_URL}/v2/devices/${id}/graph_filters`;

  const [data] = useApi({
    url,
    callType: ApiCallType.GET,
    keywords: [],

    handleSuccess,
    handleError,
    transformResponse: (data) => data.payload,
  });

  return { data };
};

export const useAvailableFiltersForEventsGraph = ({
  id,
  handleSuccess,
  handleError,
}: {
  id: string;
  handleSuccess: any;
  handleError: any;
}) => {
  const url = `${API_URL}/v2/devices/${id}/graph_filters/events`;

  const [data] = useApi({
    url,
    callType: ApiCallType.GET,
    keywords: [],

    handleSuccess,
    handleError,
    transformResponse: (data) => data.payload,
  });

  return { data };
};
