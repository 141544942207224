import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from '@cthings.co/router-dom';
import {
  useAvailableFiltersForEventsGraph,
  useGetAvailableFiltersForGraph,
} from '../../../../../api/devices/historyInsight';
import { selectAccess, selectLanguageStrings } from '../../../../../app/state/user';
import { DeviceType } from '../../../../../enums/DeviceType';
import { useNotifierFunctions } from '../../../../../features/notifier2';
import { GroundWaterDetails } from './groundWaterDetails';
import { HydrantDetails } from './hydrantDetails/HydrantDetails';
import { ManholeDetails } from './manholeDetails/ManholeDetails';
import { useDeviceAlertsData } from '../../../../../api/realTimeInsight';
import { SmartCapDetails } from './smartCapDetails/SmartCapDetails';

interface InsightsDetailsProps {}

export const InsightsDetails: FC<InsightsDetailsProps> = ({ ...props }: any) => {
  const params = useParams();
  const type = params.type as DeviceType;
  const id = params.id || '';
  const pathParams = { type, id };

  const accessData = useSelector(selectAccess).management.devices;
  const languageStrings = useSelector(selectLanguageStrings);
  const { addNotification } = useNotifierFunctions();

  const [defaultFilters, setDefaultFilters] = useState({
    periods: {
      default_value: { name: '', id: '' },
      items_list: [{ name: '', id: '' }],
    },
    intervals: { default_value: { name: '', id: '' }, items_list: [{ name: '', id: '' }] },
    averaging_methods: { default_value: { name: '', id: '' }, items_list: [{ name: '', id: '' }] },
    ts_from: 0,
    ts_to: 0,
    isLoaded: false,
  });

  const [defaultEventsFilters, setDefaultEventsFilters] = useState({
    periods: {
      default_value: { name: '', id: '' },
      items_list: [{ name: '', id: '' }],
    },
    ts_from: 0,
    ts_to: 0,
    isLoaded: false,
  });

  const handleSuccess = (data: any) => {
    setDefaultFilters({ ...data.available_filters, isLoaded: true });
  };

  const handleSuccessEvents = (data: any) => {
    setDefaultEventsFilters({ ...data.available_filters, isLoaded: true });
  };

  const handleError = () => {
    addNotification({
      message: languageStrings.filtersNotificationsError,
      type: 'error',
    });
  };

  useGetAvailableFiltersForGraph({ id, handleSuccess, handleError });

  useAvailableFiltersForEventsGraph({ id, handleSuccess: handleSuccessEvents, handleError });

  const [alertsData] = useDeviceAlertsData(id);

  const getPage = (type: DeviceType) => {
    const data: any = {
      [DeviceType.SMART_MANHOLE]: (() => (
        <ManholeDetails
          pathParams={pathParams}
          accessData={accessData}
          infoCardData={alertsData}
          defaultFilters={defaultFilters}
          defaultEventsFilters={defaultEventsFilters}
        />
      ))(),
      [DeviceType.SMART_CAP]: (() => (
        <SmartCapDetails
          pathParams={pathParams}
          accessData={accessData}
          infoCardData={alertsData}
          defaultEventsFilters={defaultEventsFilters}
        />
      ))(),
      [DeviceType.GROUND_WATER]: (() => (
        <GroundWaterDetails
          pathParams={pathParams}
          accessData={accessData}
          infoCardData={alertsData}
          defaultFilters={defaultFilters}
        />
      ))(),
      [DeviceType.HYDRANT]: (() => (
        <HydrantDetails pathParams={pathParams} accessData={accessData} infoCardData={alertsData} />
      ))(),
    };

    return data[type];
  };

  return getPage(type);
};
