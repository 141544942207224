import { AUTH_URL, CLIENT_ID, SIGNUP_URL } from '../../consts';
import { PATHS, View, getPath } from '../../routes';

const getAllowedParams = () => {
  let additionalPart = '';
  const urlParams = new URLSearchParams(window.location.search);
  const nfc = urlParams.get('nfc');
  const secret = urlParams.get('secret');
  const transport_id = urlParams.get('transport_id');
  const offset = urlParams.get('offset');
  const incident_id = urlParams.get('incident_id');

  // TODO query param for sorting from identity
  // const offset = urlParams.get('offset');

  let nextParam = !!nfc || !!secret || !!transport_id || !!offset || !!incident_id;
  if (nextParam) {
    additionalPart = additionalPart + '?';
  }
  nextParam = !!secret || !!transport_id || !!offset;
  if (nfc) {
    additionalPart = additionalPart + 'nfc=' + nfc + (nextParam ? '&' : '');
  }
  nextParam = !!transport_id || !!offset;
  if (secret) {
    additionalPart = additionalPart + 'secret=' + secret + (nextParam ? '&' : '');
  }
  nextParam = !!offset;
  if (transport_id) {
    additionalPart = additionalPart + 'transport_id=' + transport_id;
  }
  if (offset) {
    additionalPart = additionalPart + 'offset=' + offset;
  }
  if (incident_id) {
    additionalPart = additionalPart + 'incident_id=' + incident_id;
  }

  return additionalPart;
};

export const getPathFromUrl = (url: string) => {
  return url.split('?')[0];
};

export default function oauthRedirect() {
  const additionalPart = getAllowedParams();

  const redirect_uri =
    window.location.pathname === getPath(View.ERROR_401)
      ? getPathFromUrl(window.location.origin + getPath(View.USER_DASHBOARD))
      : getPathFromUrl(window.location.href);

  window.location.href = `${AUTH_URL}/?response_type=token&client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(
    redirect_uri + additionalPart,
  )}`;
  return null;
}

export function signupRedirect(languageString: string) {
  const additionalPart = getAllowedParams();

  return `${SIGNUP_URL}/?response_type=token&lang=${languageString}&client_id=nid&redirect_uri=${encodeURIComponent(
    window.location.origin + PATHS.PROVISIONING_ADDITIONAL_DEVICE + additionalPart,
  )}`;
}

export const oauthClear = () => {
  const additionalPart = getAllowedParams();
  window.location.href = getPathFromUrl(window.location.href) + additionalPart;
  return null;
};

export const clearNfc = (history: any, path: any) => {
  history.replace(path);
};
