import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Text, TextType } from '@bit/first-scope.text';
import { CommonButton } from '@cthings.co/buttons';
import { SearchBarType } from '@bit/first-scope.search-bar';
import { AddressMap } from '../../../addressMap/AddressMap';
import { MAPBOX_TOKEN } from '../../../../consts';
import { useAddress } from '../../../../api/objectPosition';
import { usePrevious } from '../../../../utils/usePrevious';
import { InputModal } from '../../../inputModal/InputModal';
import { InstructionsModal } from './components/instructionsModal/InstructionsModal';
import {
  ButtonWrapper,
  InputWrapper,
  MapWrapper,
  StyledButton,
  StyledSearchBar,
  TitleWrapper,
  Wrapper,
} from './styled';
import { CentreState, LocationPopupProps } from './types';
import { useTheme } from '@cthings.co/styled-components';
import { checkAddressReadiness } from '../../utils';

export const LocationPopup: FC<LocationPopupProps> = ({
  address,
  title,
  inputArr,
  position,
  centreLat,
  centreLon,
  zoom,
  localSearch,
  searchCentre,
  setLocalSearch,
  selectedPos,
  locationTimerId,
  handleLocationChange,
  handleSelectedPos,
  setLocalAddress,
  handleSetAddressFieldValue,
  handleCancel,
  handleSetLocalAddress,
  handleConfirm,
  languageStrings,
  altitude,
  validatingIsStarted,
  handleSaveAsMainLocation,
  isGps,
  isNotMainLocation,
  handleLatChange,
  handleLonChange,
}) => {
  const theme = useTheme();
  const { black1, pureWhite } = theme.colors;

  const searchResultName = selectedPos ? selectedPos.name : '';

  const [centre, setCentre] = useState<CentreState>({ lat: parseFloat(centreLat), lon: parseFloat(centreLon) });

  const [dragInProgress, setDragInProgress] = useState<boolean>(false);
  const [currentCentre, setCurrentCentre] = useState<CentreState>({
    lat: parseFloat(centreLat),
    lon: parseFloat(centreLon),
  });

  const targetAlt = altitude ? parseFloat(altitude) : null;

  const [resAddress1, isInitialLoad1] = useAddress(centre.lat, centre.lon, targetAlt);
  const [resAddress2, isInitialLoad2] = useAddress(searchCentre.lat, searchCentre.lon, targetAlt);

  const [isOpenInstructionsModal, setIsOpenInstructionsModal] = useState(false);

  const handleSetCentre = (centre: any) => {
    setCurrentCentre({ lat: parseFloat(centre.lat), lon: parseFloat(centre.lng) });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string, path: string[], callback: () => void) => {
    const { value } = e.target;
    handleSetLocalAddress?.(fieldName, value, path, callback);

    const handlers: { [key: string]: ((val: string) => void) | undefined } = {
      lat: handleLatChange,
      lng: handleLonChange,
    };

    if (fieldName === 'geotag' && path[0] in handlers) {
      handlers[path[0]]?.(value);
    }
  };

  useEffect(() => {
    searchResultName && setLocalSearch(searchResultName);
  }, [searchResultName]);

  useEffect(() => {
    if (
      !isGps &&
      resAddress1 &&
      checkAddressReadiness(resAddress1 as any, !isInitialLoad1) &&
      handleSetAddressFieldValue
    ) {
      handleSetAddressFieldValue(resAddress1);
      setLocalAddress(resAddress1);
    }
  }, [resAddress2, resAddress1.geotag.lng, resAddress1.geotag.lat]);

  useEffect(() => {
    if (
      !isGps &&
      resAddress2 &&
      checkAddressReadiness(resAddress2 as any, !isInitialLoad2) &&
      handleSetAddressFieldValue
    ) {
      handleSetAddressFieldValue(resAddress2);
      setLocalAddress(resAddress2);
    }
  }, [resAddress2, resAddress2.geotag.lng, resAddress2.geotag.lat]);

  useEffect(() => {
    return () => {
      locationTimerId.current && clearTimeout(locationTimerId.current);
    };
  }, []);

  const prevDrag = usePrevious(dragInProgress);

  useEffect(() => {
    if (!dragInProgress && dragInProgress !== prevDrag) {
      setCentre(currentCentre);
    }
  }, [dragInProgress]);

  return (
    <Wrapper
      onMouseDown={() => setDragInProgress && setDragInProgress(true)}
      onMouseUp={() => setDragInProgress && setDragInProgress(false)}
      onTouchStart={() => setDragInProgress && setDragInProgress(true)}
      onTouchEnd={() => setDragInProgress && setDragInProgress(false)}
    >
      <TitleWrapper>
        <Text type={TextType.TITLE_H3MOBILE} color={black1}>
          {title}
        </Text>
        {!isGps && <Text type={TextType.TEXT_14_BLACK}>{languageStrings.locationPopupDescription}</Text>}
      </TitleWrapper>
      <MapWrapper>
        {!isGps && (
          <StyledSearchBar
            type={SearchBarType.CLASSIC_LOCATION}
            countResultOfSearching={0}
            defaultValue={''}
            placeholder={languageStrings.manageClientEditAddressSearchBarPlaceholderAddress}
            locations={position}
            search={localSearch}
            setSelectedPos={handleSelectedPos}
            languageStrings={languageStrings}
            handleLocationChange={handleLocationChange}
            theme={theme}
          />
        )}

        <AddressMap
          mapToken={MAPBOX_TOKEN}
          zoom={zoom ? zoom : 8}
          latitude={searchCentre.lat}
          longitude={searchCentre.lon}
          centerCoords={searchCentre}
          handleSetCentre={!isGps ? handleSetCentre : () => {}}
          isGps={isGps}
        />
      </MapWrapper>
      <InputWrapper>
        {inputArr &&
          inputArr.map((item: any, index: number) => {
            const { isError, name, fieldName, value, placeholder, disabled, path, type, callback } = item;
            const typeOption = type ? { type } : {};

            return (
              <InputModal
                width={'100%'}
                className={index === 1 ? 'disabled' : ''}
                key={index}
                name={name}
                value={value}
                onChange={(e) => handleChange(e, fieldName, path, callback)}
                disabled={disabled}
                placeholder={placeholder}
                startedValidation={validatingIsStarted}
                isError={isError}
                {...typeOption}
              />
            );
          })}
      </InputWrapper>
      <ButtonWrapper>
        {!isGps ? (
          <>
            <StyledButton
              width="104px"
              colors={{
                main: black1,
                background: pureWhite,
              }}
              onClick={handleCancel}
            >
              {languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel}
            </StyledButton>
            <CommonButton width="104px" onClick={handleConfirm}>
              {languageStrings.insightDetailsDeviceConfigAddressButtonsConfirm}
            </CommonButton>
          </>
        ) : (
          <>
            {isNotMainLocation ? (
              <StyledButton width="104px" onClick={handleCancel}>
                {languageStrings.closeButtonValue}
              </StyledButton>
            ) : (
              <>
                <StyledButton
                  width="104px"
                  colors={{
                    main: black1,
                    background: pureWhite,
                  }}
                  onClick={handleCancel}
                >
                  {languageStrings.insightDetailsDeviceConfigurationEditInputsButtonsCancel}
                </StyledButton>
                <CommonButton width="260px" onClick={handleSaveAsMainLocation}>
                  {languageStrings.gpsDetailsSaveMainLocation}
                </CommonButton>
              </>
            )}
          </>
        )}
      </ButtonWrapper>
      <InstructionsModal
        isOpenModal={isOpenInstructionsModal}
        closeModal={() => {
          setIsOpenInstructionsModal(false);
        }}
        languageStrings={languageStrings}
      />
    </Wrapper>
  );
};
