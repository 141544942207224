import React, { FC, useEffect, useState } from 'react';
// @ts-ignore
import { useTheme } from '@xstyled/styled-components';
import { useSelector } from 'react-redux';
import { useParams } from '@cthings.co/router-dom';
import { useEntityHistory } from '../../../../../../../../../../api/devices/historyInsight';
import { selectLanguageStrings } from '../../../../../../../../../../app/state/user';
import { GraphType } from '../../../../../../../../../../components/graph/types';
import { TypeGraph } from '../../../../../../../../../../enums/GraphType';
import { InsightDataType } from '../../../../../../../../../../enums/InsightDataType';
import { useNotifierFunctions } from '../../../../../../../../../../features/notifier2';
import { StyledGraph } from '../../../../../common/styledComponents/StyledComponents';
import { GraphSensorType } from '../types';

interface WaterLevelGraphProps {
  defaultFilters: any;
}

export const WaterLevelGraph: FC<WaterLevelGraphProps> = ({ defaultFilters }) => {
  const theme = useTheme();
  const { additionalBlue1 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);
  const { addNotification } = useNotifierFunctions();

  const params = useParams();
  const { id } = params;

  const keyword = 'ManholeDetailsGraphWaterLevel';

  const [filtersAreLoaded, setFiltersAreLoaded] = useState<boolean>(false);

  const [currentFilters, setCurrentFilters] = useState(defaultFilters);
  const [savedFilters, setSavedFilters] = useState(defaultFilters);

  useEffect(() => {
    setSavedFilters(defaultFilters);
    setCurrentFilters(defaultFilters);
    setFiltersAreLoaded(defaultFilters.isLoaded);
  }, [defaultFilters.isLoaded]);

  const [applyingStatus, setApplyingStatus] = useState(0);

  const handleSuccess = (data: any) => {
    setApplyingStatus(2);
  };

  const handleError = (e: any) => {
    addNotification({ message: languageStrings.unexpectedErrorHasOccurred, type: 'error' });
    setApplyingStatus(0);
  };

  const { data: dataHistory, isLoading } = useEntityHistory({
    id: id || '',
    body: {
      sensor: GraphSensorType.WATER_LEVEL,
      filter_data_period: savedFilters.periods.default_value?.key || '',
      filter_interval: savedFilters.intervals.default_value?.key || '',
      filter_averaging_method: savedFilters.averaging_methods.default_value?.key || '',
      ts_from: savedFilters.ts_from,
      ts_to: savedFilters.ts_to,
    },
    handleSuccess,
    handleError,
    condition: filtersAreLoaded,
    triggers: [savedFilters],
    keywords: [keyword],
  });

  const { axis_labels, axis_limits, data, calibration_periods, threshold_data, unit, depth } = dataHistory;

  const payload = {
    sensor: InsightDataType.WATER_LEVEL,
    id: TypeGraph.WATERLEVEL,
    color: additionalBlue1,
    data: data.map((item: any) => ({ y: item.measurement, x: item.ts })),
    calibrationData: calibration_periods || [],
    thresholdData: threshold_data || [],
    header: axis_labels?.title || '',
    invertAxis: !axis_limits.y || axis_limits.y.max < axis_limits.y.min,
    device_id: id,
    unit: unit,
  };

  const handleChangeCurrentFilters = (fieldName: string, value: { tooltip: string; id: string; key: string }) => {
    const newCurrentFilters = { ...currentFilters };
    newCurrentFilters[fieldName] = { ...newCurrentFilters[fieldName], default_value: value };

    setCurrentFilters(newCurrentFilters);
  };

  const handleApplyFilters = () => {
    setApplyingStatus(1);
    setSavedFilters(currentFilters);
  };

  return (
    <StyledGraph
      placeholderProps={{
        keyword,
        style: { width: '100%', margin: '0' },
      }}
      labels={{
        yAxis: `(${depth + ' m depth'})`,
      }}
      data={payload}
      header={`${languageStrings.graphPlaceholderLabel}`}
      filters={currentFilters}
      setCurrentFilters={setCurrentFilters}
      type={GraphType.WATER_LEVEL}
      handleChangeCurrentFilters={handleChangeCurrentFilters}
      applyOnClick={handleApplyFilters}
      applyingStatus={applyingStatus}
      setApplyingStatus={setApplyingStatus}
      isLoadingInProcess={isLoading}
      limits={axis_limits}
      first
    />
  );
};
