import styled from '@cthings.co/styled-components';
import { Text } from '@bit/first-scope.text';
import { media } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

type WrapperProps = {
  isSelected: boolean;
  additionalShadow10: string;
};

export const Wrapper = styled.div<WrapperProps>`
  width: 314px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid;
  border-color: ${({ isSelected, theme }) =>
    isSelected ? colorFetch('secondary3')({ theme }) : colorFetch('gray4')({ theme })};
  border-radius: 6px;
  background-color: white;
  cursor: pointer;

  &:hover {
    border-color: ${colorFetch('secondary3')};
    box-shadow: ${({ additionalShadow10 }) => additionalShadow10};
  }

  ${media.narrowMonitor} {
    width: 100%;
  }
  ${media.tablet} {
    width: auto;
  }
`;

export const StyledText = styled(Text)`
  color: ${colorFetch('gray1')};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;

  &.title {
    color: ${colorFetch('primary')};
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  &.sub-title {
    color: ${colorFetch('primary')};
    font-weight: 400;
  }
  &.price {
    color: black;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    margin-right: 10px;
  }
  &.price-subtitle {
    color: ${colorFetch('gray2')};
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }
  &.content-subtitle {
    font-weight: 500;
  }
` as any; // @TODO fix type

type RowProps = {
  isIncluded?: boolean;
};

export const Row = styled.div<RowProps>`
  display: flex;

  &.title {
    justify-content: space-between;
  }
  &.sub-title {
    margin-bottom: 10px;
  }
  &.price {
    align-items: center;
    justify-content: ${({ isIncluded }) => (isIncluded ? 'center' : 'space-between')};
  }
  &.content {
    margin-bottom: 8px;
    &:first-child {
      margin-top: 18px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  &.price {
    margin-bottom: 18px;
  }
`;

export const Circle = styled.div`
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-color: ${colorFetch('gray4')};
  border-radius: 56px;
`;

export const UnderLine = styled.div`
  width: 100%;
  border: 1px solid;
  border-color: ${colorFetch('gray4')};
  background-color: ${colorFetch('gray4')};
`;

export const StyledList = styled.ul`
  list-style: none;
  margin: 0;
`;

export const ItemList = styled.li`
  color: ${colorFetch('gray1')};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  &:before {
    content: '• ';
    color: black;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

export const Icon = styled.div`
  margin-right: 8px;
`;
