import React, { useCallback, useEffect, useState } from 'react';
import styled from '@cthings.co/styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from '../../../../utils/react-router-dom-abstraction';
import { useGetClients } from '../../../../api/manage/manageClients';
import { useTableFunctions, withTableContextProvider } from '../../../../features/universalTable/context';
import { ActionType, InlineActionType, RowItemType } from '../../../../features/universalTable/types';
import { UniversalTable } from '../../../../features/universalTable/UniversalTable';
import { getPath, injectPathParams, PATHS } from '../../../../routes/paths';
import { useMediaType, useWindowSize } from '@cthings.co/styles-utils';
import { selectAccess, selectIsAccess, selectLanguageStrings } from '../../../../app/state/user';
import { ManageLayout, ManageTableType } from '../../../../components/manageLayout/ManageLayout';
import { LocationButton } from './components/locationButton/LocationButton';
import { API_URL, MAPBOX_FLAG } from '../../../../consts';
import { View } from '../../../../routes/routeInterfaces';
import { getRequiredDateFormat } from '../../../../utils/date/date-format';
import { SubscribeButton } from './components/subscribeButton/SubscribeButton';
import { useLoggedUserInfo } from '../../../../api/manage/manageUsers';
import { subscribeToDevice, unsubscribeToDevice } from '../../../../api/manage/manageDevices';
import { useMobileManagementSearch } from '../../../../layouts/managementMobile/useMobileManagement';
import { useNotifierFunctions } from '../../../../features/notifier2';
import { Text, TextType } from '@bit/first-scope.text';
import { ExportModal } from './components/exportModal/ExportModal';
import { selectIsExportModalOpen, setExportModalState } from '../../../../app/state/devicesTable';
import { useQueryParams } from '../../../../utils/useQueryParams';
import { useParams } from '@cthings.co/router-dom';
import { useTheme } from '@cthings.co/styled-components';
import { stringifyUrl } from 'query-string';
import { BatteryLvl } from './components/batteryLvl/BatteryLvl';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export enum DeviceType {
  HYDRANT = 'hydrant',
  SMART_CAP = 'smart_cap',
  SMART_MANHOLE = 'smart_manhole',
  WATER_GROUND = 'water_ground_meter',
}

const DevicesPlain = () => {
  const theme = useTheme();
  const { resetTable, getSearchValue, setSearchValue } = useTableFunctions();
  const history = useHistory();
  const isAccess = useSelector(selectIsAccess);
  const accessData = useSelector(selectAccess).management.devices;
  const isDevicesAccessManagementView = accessData.view;
  const languageStrings = useSelector(selectLanguageStrings);
  const mediaType = useMediaType();
  const keyword = 'DevicesTable';
  const offsetKey = 'offset';
  const sortingKeys = { sort_type: 'sort_type', sort_field: 'sort_field' };
  const queryParams = useQueryParams() || {};

  const offset = queryParams[offsetKey] || '0';
  const sortingData = {
    sort_type: queryParams[sortingKeys.sort_type] || '',
    sort_field: queryParams[sortingKeys.sort_field] || '',
  };

  const pathParams = useParams();

  const [userDeviceIds, setUserDeviceIds] = useState<string[]>();
  const [subscribeError, setSubscribeError] = useState(false);
  const userInfo = useLoggedUserInfo();

  const { search_value = '' } = queryParams || {};

  const screenWidth = useWindowSize()[0];
  const { addNotification } = useNotifierFunctions();
  const { gray1 } = theme.colors;
  const dispatch = useDispatch();

  const isOpenExportModal = useSelector(selectIsExportModalOpen);

  const openExportModal = () => {
    dispatch(setExportModalState(true));
  };

  const closeExportModal = useCallback(() => {
    dispatch(setExportModalState(false));
  }, [dispatch]);
  /*const exportDeviceDetailsData = (setIsDataExporting: (isExporting: boolean) => void, closeModal: () => void) => {
    //@TODO Alex discuss with Bartek the flow of download functionality
    exportDeviceDetails(addNotification, setIsDataExporting, closeModal);
  };*/

  const onSubscribeBtnPress = (deviceId: string, isSubscribed: boolean) => {
    isSubscribed
      ? unsubscribeToDevice(deviceId, languageStrings, addNotification, setUserDeviceIds, setSubscribeError)
      : subscribeToDevice(deviceId, languageStrings, addNotification, setUserDeviceIds, setSubscribeError);
  };

  useEffect(() => {
    if (isAccess && !isDevicesAccessManagementView) {
      history.push(PATHS.ERROR_401);
    }
  }, [isAccess, isDevicesAccessManagementView]);

  useEffect(() => {
    return () => {
      resetTable();
    };
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.subscribed_devices) {
      setUserDeviceIds(userInfo.subscribed_devices);
    }
  }, [userInfo]);

  const clients = useGetClients();

  useMobileManagementSearch(setSearchValue);

  return (
    <ManageLayout
      type={ManageTableType.DEVICES_TABLE}
      title={languageStrings.navigationDevices}
      accessData={accessData}
      searchValue={search_value}
      setSearchValue={setSearchValue}
      openExportModal={openExportModal}
      withSmartSearch
    >
      <Wrapper>
        <UniversalTable
          placeholderProps={{
            keyword,
            placeholderOptions: { borderRadiusTable: !mediaType.tablet },
          }}
          mainApiUrl={`${API_URL}/v2/devices/`}
          keyword={keyword}
          offset={+offset}
          offsetKey={offsetKey}
          sortingData={sortingData}
          sortingKeys={sortingKeys}
          pathParams={pathParams}
          queryParams={queryParams}
          tablePath={View.MANAGE_DEVICES}
          actionSet={[{ type: ActionType.EDIT }]}
          updateItems={() => {}}
          accessData={accessData}
          isHrefDetailsClick={true}
          customRowClick={(value) => {
            const to = View.USER_MANAGE_DEVICES_DETAILS;
            const href = to
              ? stringifyUrl({
                  url: injectPathParams(getPath(to), { id: value.id, offset: 0 }),
                })
              : undefined;
            return href;
          }}
          inlineActionSet={
            MAPBOX_FLAG
              ? [
                  {
                    type: InlineActionType.CUSTOM,
                    component: (data: any) =>
                      data?.type_id !== DeviceType.SMART_CAP && screenWidth >= 1024 ? (
                        <SubscribeButton
                          subscribedDevices={userDeviceIds}
                          deviceData={data}
                          languageStrings={languageStrings}
                          subscribeError={subscribeError}
                          onSubscribeBtnPress={onSubscribeBtnPress}
                          setSubscribeError={setSubscribeError}
                        />
                      ) : null,
                  },
                  {
                    type: InlineActionType.CUSTOM,
                    component: <LocationButton onClick={() => {}} />,
                    callback: (data: any) => {
                      const { address, id } = data;
                      const to = injectPathParams(PATHS.USER_INSIGHTS, {
                        lat: address?.geotag?.lat,
                        lon: address?.geotag?.lng,
                        zoom: 20,
                      });
                      history.push(to, { address, zoom: 20, id });
                    },
                  },
                  {
                    type: InlineActionType.DETAILS,
                  },
                ]
              : [
                  {
                    type: InlineActionType.CUSTOM,
                    component: (data: any) =>
                      data?.type_id !== DeviceType.SMART_CAP && screenWidth >= 1024 ? (
                        <SubscribeButton
                          subscribedDevices={userDeviceIds}
                          deviceData={data}
                          languageStrings={languageStrings}
                          subscribeError={subscribeError}
                          onSubscribeBtnPress={onSubscribeBtnPress}
                          setSubscribeError={setSubscribeError}
                          className={'alone'}
                        />
                      ) : null,
                  },
                  {
                    type: InlineActionType.DETAILS,
                  },
                ]
          }
          columnStructure={[
            {
              fieldName: 'device_name',
              placeholder: '',
              label: languageStrings.deviceName,
              defaultValue: '',
              disabled: true,
              getTextDisplay: (value: any) => (
                <Text type={TextType.TEXT_14_BLACK} color={gray1} weight={'500'}>
                  {value || 'N/A'}
                </Text>
              ),
            },
            {
              fieldName: 'transport_id',
              placeholder: languageStrings.insightDetailsDeviceConfigurationEditInputsNamePlaceholder,
              label: languageStrings.phisicalId,
              defaultValue: '',
              disabled: true,
              getTextDisplay: (value: any) => (value ? `${value}` : 'N/A'),
            },
            {
              fieldName: 'type',
              placeholder: '',
              label: languageStrings.insightDetailsDeviceConfigurationEditInputsTypeTitle,
              defaultValue: '',
              disabled: true,
            },
            {
              fieldName: 'address',
              placeholder: '',
              label: languageStrings.manageClientsTableHeaderItemsPlace,
              defaultValue: { city: '', line1: '' },
              disabled: true,
              getTextDisplay: (value: any) => {
                return `${value ? `${value?.city}, ${value?.line1}` : 'N/A'}`;
              },
            },
            {
              fieldName: { name: 'client_name', id: 'client_id' },
              type: RowItemType.SELECT,
              placeholder: languageStrings.mobileEditPopupChooseClient,
              label: languageStrings.manageClientsTableHeaderItemsClient,
              defaultValue: clients && (clients as any).length > 0 && (clients[0] as any).id,
              selectItems: clients,
            },
            {
              fieldName: 'battery_state',
              placeholder: '',
              label: languageStrings.battery,
              defaultValue: '',
              type: RowItemType.CUSTOM,
              component: (value: any) => <BatteryLvl type={value} />,
            },
            {
              fieldName: 'last_seen_date',
              placeholder: '',
              label: languageStrings.lastSeenTitle,
              defaultValue: '',
              disabled: true,
              getTextDisplay: (value: any) => {
                return value ? getRequiredDateFormat(value, 'DD.MM.YYYY, HH:mm') : 'N/A';
              },
            },
          ]}
        />
      </Wrapper>
      <ExportModal isOpenModal={isOpenExportModal} closeModal={closeExportModal} languageStrings={languageStrings} />
    </ManageLayout>
  );
};

export const Devices = withTableContextProvider(DevicesPlain);
