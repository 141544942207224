import React from 'react';
import styled from '@cthings.co/styled-components';
import { media } from '@cthings.co/styles-utils';
import { GraphRow } from '../../../common/styledComponents/StyledComponents';
import { EventsGraph } from './components/eventsGraph/EventsGraph';

const StyledGraphRow = styled(GraphRow)`
  margin-top: 25px;
  gap: 40px;
  ${media.tablet} {
    margin-top: 45px;
  }
`;

export const History = ({ defaultEventsFilters }: { defaultEventsFilters: any }) => {
  return (
    <StyledGraphRow>
      <EventsGraph defaultEventsFilters={defaultEventsFilters} isSmartCapDevice />
    </StyledGraphRow>
  );
};
