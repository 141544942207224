import { Text, TextType } from '@bit/first-scope.text';
import { useTheme } from '@cthings.co/styled-components';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@cthings.co/styled-components';
import { selectLanguage, selectLanguages, setLanguage } from '../../app/state/user';
import { LsValueType } from '../../enums/LsValueType';
import { media } from '@cthings.co/styles-utils';
import { useStyleContext } from '@cthings.co/styles-utils';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { ReactComponent as DKIcon } from './assets/denmark.svg';
import { ReactComponent as ENGIcon } from './assets/english.svg';
import { ReactComponent as DEIcon } from './assets/germany.svg';
import { ReactComponent as PLIcon } from './assets/poland.svg';
import { ReactComponent as SEIcon } from './assets/sweden.svg';
import { CountryTypes } from './types';
import ss from '../../utils/ss';

const Wrapper = styled.div`
  position: relative;

  &:focus {
    outline: none;
  }
`;

type SelectWrapperProps = {
  isOpen: boolean;
};

const SelectWrapper = styled.div<SelectWrapperProps>`
  box-sizing: border-box;
  display: flex;
  width: 160px;
  padding: 4px 12px;
  background: transparent;
  border: 1px solid;
  border-color: ${({ isOpen, theme }) => (isOpen ? colorFetch('primary')({ theme }) : colorFetch('gray3')({ theme }))};
  border-radius: ${({ theme }) => theme.borderRadius.additional6};
  transition: all 0.3s ease;
  cursor: pointer;
  user-select: none;

  ${media.phone} {
    width: 70px;
  }
`;

const TextImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlagIconWrapper = styled.div`
  display: flex;
  width: 22px;
  height: 16px;
  margin-top: 3px;
  overflow: hidden;
`;

const StyledText = styled(Text)`
  flex: 1;
  overflow-wrap: break-word;
`;

type ArrowImageProps = {
  isOpen: boolean;
};

const ArrowImage = styled(ArrowIcon)<ArrowImageProps>`
  transition: all 0.3s ease;
  display: inline-block;
  margin-top: 10px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : '')};

  ${media.phone} {
    margin-top: 6px;
  }
`;

type OptionsWrapperProps = {
  isOpen: boolean;
};

const OptionsWrapper = styled.div<OptionsWrapperProps>`
  position: absolute;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  width: 158px;
  height: 164px;
  top: 36px;
  background: ${colorFetch('pureWhite')};
  border: 1px solid;
  border-color: ${colorFetch('gray4')};
  border-radius: ${({ theme }) => theme.borderRadius.additional6};
  box-shadow: ${({ theme }) => theme.shadows.additionalShadow12};

  ${media.phone} {
    width: 68px;
    top: 60px;
  }
`;

const Option = styled.div`
  padding: 1px 0;
  margin-top: 2px;
  padding: 4px 12px;
  user-select: none;
  display: flex;
  cursor: pointer;

  &:hover > div > span {
    color: ${colorFetch('primary')};
  }
  &:first-child {
    margin-top: 6px;
  }
`;

export interface LanguageSelectorProps {
  colors?: any;
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({ colors = {}, ...props }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { primary, gray1 } = theme.colors;
  const languages = useSelector(selectLanguages);
  const lang = useSelector(selectLanguage);
  const [{ mediaType }] = useStyleContext();
  const { phone } = mediaType;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | null>(lang.id);

  const selectedItem = languages.find((i) => i.id === selectedId);

  const handleSelectCountry = (country: any) => {
    const langId = country.id;
    ss.set(LsValueType.locale, langId);
    dispatch(setLanguage(langId));
    setSelectedId(langId);
    setIsOpen(false);
  };

  const selectRef = useRef<any>(null);
  const outsideHandler = useCallback(
    (e: any) => {
      if (isOpen && !selectRef.current?.contains(e.target)) {
        setIsOpen(false);
      }
    },
    [isOpen],
  );

  useEffect(() => {
    window.addEventListener('click', outsideHandler);
    return () => {
      window.removeEventListener('click', outsideHandler);
    };
  }, [outsideHandler]);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const switchIcons = (type: string) => {
    switch (type) {
      case CountryTypes.EN:
        return <ENGIcon />;
      case CountryTypes.PL:
        return <PLIcon />;
      case CountryTypes.DK:
        return <DKIcon />;
      case CountryTypes.SE:
        return <SEIcon />;
      case CountryTypes.DE:
        return <DEIcon />;
    }
  };

  return (
    <Wrapper {...props}>
      <SelectWrapper isOpen={isOpen} onClick={handleToggleOpen} ref={selectRef}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <TextImageWrapper>
            <FlagIconWrapper>{selectedItem?.id && <>{switchIcons(selectedItem?.id)}</>}</FlagIconWrapper>
            {!phone && (
              <StyledText color={gray1} theme={theme} type={TextType.TEXT_14_BLACK} weight={'400'} margin={'0 0 0 8px'}>
                {selectedItem?.name}
              </StyledText>
            )}
          </TextImageWrapper>
          <ArrowImage isOpen={isOpen} />
        </div>
      </SelectWrapper>
      <OptionsWrapper isOpen={isOpen}>
        {languages.map((country) => (
          <Option key={country.id} onClick={() => handleSelectCountry(country)}>
            <TextImageWrapper>
              <FlagIconWrapper>{switchIcons(country.id)}</FlagIconWrapper>
              {!phone && (
                <StyledText
                  color={country === selectedItem ? primary : gray1}
                  theme={theme}
                  type={TextType.TEXT_14_BLACK}
                  weight={country === selectedItem ? '500' : '400'}
                  lineHeight={'21px'}
                  margin={'0 0 0 10px'}
                >
                  {country.name}
                </StyledText>
              )}
            </TextImageWrapper>
          </Option>
        ))}
      </OptionsWrapper>
    </Wrapper>
  );
};
