import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleRegisterNewEvent } from '../../../../../../api/devices/historyInsight';
import { deployDevice, useRealTimeInsights } from '../../../../../../api/realTimeInsight';
import { selectNfcFlag, setNfcFlag } from '../../../../../../app/state/app';
import { resetRealTimeData } from '../../../../../../app/state/insight';
import { selectIsAccess, selectLanguageStrings } from '../../../../../../app/state/user';
import { Loader } from '../../../../../../components/placeholders/Loader';
import { useNotifierFunctions } from '../../../../../../features/notifier2';
import { useLoaderFunctions } from '../../../../../../features/placeholderComponent/loader.context';
import { PATHS } from '../../../../../../routes/paths';
import { StatusOfCard } from '../../../../../../types/statusOfCards';
import { getRequiredDateFormat } from '../../../../../../utils/date/date-format';
import { useHistory } from '../../../../../../utils/react-router-dom-abstraction';
import { DeployBlock } from '../common/deployBlock/DeployBlock';
import { EventTableBlock } from '../common/eventTableBlock/EventTableBlock';
import { InsightsHeader } from '../common/insightsHeader/InsightsHeader';
import { Content, Wrapper } from '../common/styledComponents/StyledComponents';
import { Cards } from './components/cards/Cards';
import { History } from './components/history/History';
import { DeviceTitle } from './components/deviceTitle/DeviceTitle';
import { NFCModal } from '../hydrantDetails/components/nFCModal/NFCModal';

interface SmartCapDetailsProps {
  pathParams: { id: string; type: string };
  accessData?: { view: boolean; edit: boolean; delete: boolean };
  infoCardData?: any;
  defaultEventsFilters: any;
}

export const SmartCapDetails: FC<SmartCapDetailsProps> = ({
  pathParams,
  accessData,
  infoCardData,
  defaultEventsFilters,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const languageStrings = useSelector(selectLanguageStrings);
  const isAccess = useSelector(selectIsAccess);
  const nfcFlag = useSelector(selectNfcFlag);

  const [deploymentStatus, setDeploymentStatus] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [nfcMessage, setNfcMessage] = useState('');

  const { addNotification } = useNotifierFunctions();
  const { appendKeywordList, deleteFromKeywordList } = useLoaderFunctions();

  const id = pathParams.id || '';
  const type = pathParams.type || '';
  const isInsigthDetailsView = accessData?.view;

  const keywordList = [
    'SmartCapDetailsMap',
    'SmartCapDetailsProblemsButton',
    'SmartCapDetailsCard',
    'SmartCapDetailsGraphWaterLevel',
    'SmartCapDetailsGraphTemperature',
    'SmartCapDetailsEventsGraph',
    'SmartCapDetailsDeviceConfigurationTable',
    'setDepthModalLoader',
  ];

  // Main data
  const data: any = useRealTimeInsights(
    id,
    (isPlaceholder: boolean) => appendKeywordList(keywordList, isPlaceholder),
    (isPlaceholder: boolean) => deleteFromKeywordList(keywordList, isPlaceholder),
  );

  // variabels from data
  const { address, transport_id: idDevice, deployment_status, icon_type, human_readable_type } = data?.info;
  const { rt } = data.data;
  const { humidity, temp } = rt || {};

  const isDeployed = deployment_status === 2;

  const statusOfValue = (status: number) => {
    const data: any = {
      0: StatusOfCard.NORMAL,
      1: StatusOfCard.WARNING,
      2: StatusOfCard.CRITICAL,
    };

    return data[status] || StatusOfCard.NORMAL;
  };

  const handleCopyText = async (copiedText: string) => {
    await navigator?.clipboard?.writeText(copiedText);
    addNotification({ message: languageStrings.copiedMessage, type: 'info' });
  };

  const onDeployPress = () => {
    setIsLoading(true);
    deployDevice(id, languageStrings, addNotification, setDeploymentStatus, setIsLoading);
  };

  useEffect(() => {
    return () => {
      dispatch(resetRealTimeData());
    };
  }, []);

  useEffect(() => {
    if (isAccess && !isInsigthDetailsView) {
      history.push(PATHS.ERROR_401);
    }
  }, [isAccess, isInsigthDetailsView]);

  useEffect(() => {
    if (nfcFlag && idDevice && id) {
      const currentDate = new Date();
      const nfcData = {
        event_id: 5,
        is_problem: false,
        device_id: id,
        transport_id: idDevice,
        address,
        ts: getRequiredDateFormat(currentDate, 'YYYY-MM-DDTHH:mm:ss'),
      };
      handleRegisterNewEvent(nfcData, setNfcMessage);
    }
  }, [nfcFlag, idDevice, id]);

  useEffect(() => {
    setDeploymentStatus(deployment_status);
  }, [deployment_status]);

  return (
    <Wrapper>
      <InsightsHeader
        languageStrings={languageStrings}
        address={address}
        type={icon_type || type}
        id={id}
        isDeployed={isDeployed}
        isShowRecipientsBlock={false}
      />
      <Content>
        <DeviceTitle
          address={address}
          deviceId={idDevice}
          handleCopyText={handleCopyText}
          deviceType={human_readable_type}
        />
        {deploymentStatus !== undefined ? (
          isDeployed ? (
            <>
              <Cards
                humidity={humidity}
                temp={temp}
                statusOfHumidity={statusOfValue(humidity.tile_color)}
                statusOfTemperature={statusOfValue(temp.tile_color)}
                infoCardData={infoCardData}
              />
              <History defaultEventsFilters={defaultEventsFilters} />
              <EventTableBlock languageStrings={languageStrings} pathParams={pathParams} isInDevices />
            </>
          ) : (
            <DeployBlock
              id={id}
              languageStrings={languageStrings}
              isLoading={isLoading}
              deploymentStatus={deploymentStatus}
              onDeployPress={onDeployPress}
              photos={data.info.images}
              infoCardData={infoCardData}
            />
          )
        ) : (
          <Loader />
        )}
      </Content>

      <NFCModal
        isOpenModal={nfcFlag && !!nfcMessage}
        handleCloseModal={() => {
          dispatch(setNfcFlag(false));
        }}
        languageStrings={languageStrings}
        message={nfcMessage}
      />
    </Wrapper>
  );
};
