import styled from '@cthings.co/styled-components';
import React, { FC, useCallback, useEffect, useState } from 'react';
// @ts-ignore
import { Map, Marker } from 'react-map-gl';
import { useSelector } from 'react-redux';
import { useHistory } from '../../utils/react-router-dom-abstraction';
import useSupercluster from 'use-supercluster';
import { selectLanguageStrings } from '../../app/state/user';
import greenCircle from '../../assets/ellipse-green.png';
import { ManholeButton } from '../../components/manholeButton/ManholeButton';
import { DeviceType } from '../../enums/DeviceType';
import { useMapResizeControl } from '../../features/mapResizeControl/useMapResizeControl';
import { withLoader } from '../../features/placeholderComponent/loaderFunctions';
import { injectPathParams, PATHS } from '../../routes/paths';
import { media } from '@cthings.co/styles-utils';
import { getRequiredDateFormat } from '../../utils/date/date-format';
import { OverlayGradient } from '../overlayGradient/OverlayGradient';
import { PlaceholderType } from '../placeholders/typePlaceholders/placeholdersType';
import { CustomMarker } from './components/customMarker/CustomMarker';
import { useTheme } from '@cthings.co/styled-components';

import { colorFetchFDS as colorFetch } from '@cthings.co/styles-utils';

export enum DeviceListMapType {
  DASHBOARD = 'DASHBOARD',
  INSIGHT = 'INSIGHT',
}

export enum ManholesType {
  GREEN = 'GREEN',
  RED = 'RED',
  YELLOW = 'YELLOW',
  NONE = 'NONE',
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid;
  border-color: ${colorFetch('gray3')};
  ${media.tablet} {
    border-radius: 0;
  }
`;

const SemiWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.additional10};
  & > div {
    height: 100% !important;
  }
  ${media.tablet} {
    & > div {
      width: 100%;
    }
  }
`;

type ClusterProps = {
  size: number;
  fontSize: string;
};

const Cluster = styled.div<ClusterProps>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colorFetch('white')};
  font-family: 'Poppins';
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 500;
  line-height: 25px;
  border-radius: 50%;
  cursor: pointer;
  /* transform: translate(0, -2px); */
  position: relative;
  transition: all 0.3s ease;
  border: 3px solid;
  background-color: ${colorFetch('primary')};
  border-color: ${colorFetch('secondary3')};
  &:hover {
    background-color: ${colorFetch('secondary2')};
    border-color: ${colorFetch('primary')};
  }
`;

export interface CenterCoordsType {
  lat: number;
  lon: number;
}
interface CallbackRef {
  current: any;
}
export interface DeviceMapProps {
  type: DeviceListMapType;
  zoom: number;
  latitude?: number;
  longitude?: number;
  centerCoords?: CenterCoordsType;
  isGradiented?: boolean;
  staticDisplay?: boolean;
  devices: any[];
  className?: string;
  setCentre?: (el: any) => void;
  mapToken: string;
  dataLocationCluster?: any;
  isInfoManholeId?: string;
  setisInfoManholeId?: any;
}

//@TODO Alex split this component into smaller ones

const DeviceListMapPlain: FC<DeviceMapProps> = ({
  type,
  zoom,
  isGradiented,
  latitude,
  longitude,
  centerCoords,
  staticDisplay,
  devices,
  mapToken,
  setCentre,
  dataLocationCluster,
  isInfoManholeId,
  setisInfoManholeId,
  ...props
}) => {
  const theme = useTheme();
  const history = useHistory();
  const languageStrings = useSelector(selectLanguageStrings);

  const [viewport, setViewport] = useState({
    latitude: latitude,
    longitude: longitude,
    width: '100%',
    height: '100%',
    zoom: zoom,
    transitionDuration: 400,
  });

  const mapbox = useMapResizeControl(setViewport);

  const [mapnode, setMapnode] = useState<CallbackRef>({ current: undefined });
  const mapReference = useCallback((node: any) => {
    if (node !== null) {
      setMapnode({ current: node });
    }
  }, []);

  useEffect(() => {
    const map = mapnode.current?.getMap();
    map &&
      map.loadImage(greenCircle, (error: any, image: any) => {
        if (error) throw error;
        if (!map.hasImage('circle-green')) map.addImage('circle-green', image, { sdf: true });
      });
  }, [mapnode]);

  const points = devices.map((values: any) => {
    return {
      type: 'Feature',
      properties: {
        cluster: false,
        values: values.map((value: any) => {
          return {
            deviceType: value?.type,
            iconType: values.length > 1 ? 'cluster' : value?.icon_type,
            multipleDevicesType: value?.icon_type,
            type_event: value?.event_type || 'N/A',
            manholeId: value?.id,
            name: value?.name || 'N/A',
            temperature: {
              measurement:
                !staticDisplay && value?.rt_data?.temp && !value?.devices
                  ? `${value?.rt_data?.temp?.measurement} ${value?.rt_data?.temp?.unit}`
                  : !staticDisplay && value?.devices && value?.devices[0]?.rt_data?.temp
                  ? `${value?.devices[0]?.rt_data?.temp?.measurement} ${value?.devices[0]?.rt_data?.temp?.unit}`
                  : 'N/A',
              tooltipText:
                !staticDisplay && value?.rt_data?.temp
                  ? `${value?.rt_data?.temp?.tile_name}`
                  : languageStrings.temperatureTooltip,
            },
            waterLevel: {
              measurement:
                !staticDisplay && value?.rt_data?.water_level && !value?.devices
                  ? `${value?.rt_data?.water_level?.measurement ?? value?.rt_data?.ground_water_level?.measurement} ${
                      value?.rt_data?.water_level?.unit ?? value?.rt_data?.ground_water_level?.unit
                    }`
                  : !staticDisplay && value?.devices && value?.devices[0]?.rt_data?.water_level
                  ? `${value?.devices[0]?.rt_data?.water_level?.measurement} ${value?.devices[0]?.rt_data?.water_level?.unit}`
                  : 'N/A',
              tooltipText:
                !staticDisplay && value?.rt_data?.water_level
                  ? `${value?.rt_data?.water_level?.tile_name}`
                  : languageStrings.waterLevelTooltip,
            },
            distanceToWater: {
              measurement:
                !staticDisplay && value?.rt_data?.distance_to_water && !value?.devices
                  ? `${value?.rt_data?.distance_to_water?.measurement} ${value?.rt_data?.distance_to_water?.unit}`
                  : !staticDisplay && value?.devices && value?.devices[0]?.rt_data?.distance_to_water
                  ? `${value?.devices[0]?.rt_data?.distance_to_water?.measurement} ${value?.devices[0]?.rt_data?.distance_to_water?.unit}`
                  : 'N/A',
              tooltipText:
                !staticDisplay && value?.rt_data?.distance_to_water
                  ? `${value?.rt_data?.distance_to_water?.tile_name}`
                  : languageStrings.distanceToWaterTooltip,
            },
            waterFlowInfo: value?.rt_data?.water_flow
              ? {
                  ...value?.rt_data?.water_flow,
                  name: value?.name || null,
                  setType: value?.set_type || null,
                  to:
                    !staticDisplay &&
                    injectPathParams(
                      value?.rt_data?.water_flow?.set_type === DeviceType.MANNINGS_PAIR
                        ? PATHS.USER_PAIR_DEVICE_INSIGHTS
                        : PATHS.USER_MEASUREMENT_DEVICE_INSIGHTS,
                      {
                        id: value?.rt_data?.water_flow?.set_oid,
                      },
                    ),
                }
              : null,
            waterFlowData: value?.devices?.length
              ? value?.devices.map((device: any) => {
                  return {
                    devicesType: device?.type,
                    address: device?.address?.line1 || 'N/A',
                    coordinates: `${device?.address?.geotag?.lat}, ${device?.address?.geotag?.lng}` || 'N/A',
                    name: device?.name,
                    temperature: {
                      measurement:
                        !staticDisplay && device?.rt_data?.temp
                          ? `${device?.rt_data?.temp?.measurement} ${device?.rt_data?.temp?.unit}`
                          : 'N/A',
                      tooltipText:
                        !staticDisplay && device?.rt_data?.temp
                          ? `${device?.rt_data?.temp?.tile_name}`
                          : languageStrings.temperatureTooltip,
                    },
                    waterLevel: {
                      measurement:
                        !staticDisplay && device?.rt_data?.water_level
                          ? `${
                              device?.rt_data?.water_level?.measurement ??
                              device?.rt_data?.ground_water_level?.measurement
                            } ${device?.rt_data?.water_level?.unit ?? device?.rt_data?.ground_water_level?.unit}`
                          : 'N/A',
                      tooltipText:
                        !staticDisplay && device?.rt_data?.water_level
                          ? `${device?.rt_data?.water_level?.tile_name}`
                          : languageStrings.waterLevelTooltip,
                    },
                    distanceToWater: {
                      measurement:
                        !staticDisplay && device?.rt_data?.distance_to_water
                          ? `${device?.rt_data?.distance_to_water?.measurement} ${device?.rt_data?.distance_to_water?.unit}`
                          : 'N/A',
                      tooltipText:
                        !staticDisplay && device?.rt_data?.distance_to_water
                          ? `${device?.rt_data?.distance_to_water?.tile_name}`
                          : languageStrings.distanceToWaterTooltip,
                    },
                    date:
                      !staticDisplay &&
                      (device?.rt_data?.ts ? getRequiredDateFormat(device?.rt_data?.ts, 'DD.MM.YYYY') : 'N/A'),
                    time:
                      !staticDisplay &&
                      (device?.rt_data?.ts ? getRequiredDateFormat(device?.rt_data?.ts, 'HH:mm') : 'N/A'),
                    to:
                      !staticDisplay &&
                      injectPathParams(PATHS.USER_INSIGHTS_DETAILS, {
                        id: device?.id,
                        type: device?.type,
                      }),
                  };
                })
              : null,
            cover: (value?.rt_data?.cover ?? null) || 'N/A',
            outlet: (value?.rt_data?.outlet ?? null) || 'N/A',
            waterLevelHydrant: (value?.rt_data?.water_level ?? null) || 'N/A',
            date:
              !staticDisplay && (value?.rt_data?.ts ? getRequiredDateFormat(value.rt_data.ts, 'DD.MM.YYYY') : 'N/A'),
            time: !staticDisplay && (value?.rt_data?.ts ? getRequiredDateFormat(value.rt_data.ts, 'HH:mm') : 'N/A'),
            address: value?.address?.line1 || 'N/A',
            deployment_date: value?.devices ? value?.devices[0]?.deployment_date : value?.deployment_date,
            deployment_status: value?.devices ? value?.devices[0]?.deployment_status : value?.deployment_status,
            to:
              !staticDisplay &&
              injectPathParams(
                value?.icon_type === DeviceType.WATER_FLOW
                  ? PATHS.USER_MEASUREMENT_DEVICE_INSIGHTS
                  : PATHS.USER_INSIGHTS_DETAILS,
                {
                  id: value?.id,
                  type: value?.type,
                },
              ),
            coordinates: `${value?.address?.geotag?.lat}, ${value?.address?.geotag?.lng}` || 'N/A',
          };
        }),
      },
      geometry: {
        type: 'Point',
        coordinates: values ? [values[0].address.geotag.lng, values[0].address.geotag.lat] : [],
      },
      countOfDevices: values.length,
    };
  });

  const bounds = mapnode.current ? mapnode.current.getMap().getBounds().toArray().flat() : null;

  const { clusters, supercluster } = useSupercluster({
    points,
    zoom: viewport.zoom,
    bounds,
    options: {
      radius: 100,
      map: (props: any) => ({
        count: props.values.length,
      }),
      reduce: (acc: any, props: any) => {
        acc.count += props.count;
        return acc;
      },
    },
  });

  const onSelectMarker = (longitude: number, latitude: number, zoom?: number, duration?: number) => {
    mapnode.current?.easeTo({
      center: [longitude, latitude],
      zoom,
      duration: duration || 400,
    });
  };

  const getExpansionZoom = (clusterId: string) => Math.min(supercluster.getClusterExpansionZoom(clusterId), 20);

  const handleClusterClick = (latitude: number, longitude: number, clusterId: string) => {
    const expansioZoom = getExpansionZoom(clusterId);
    onSelectMarker(longitude, latitude, expansioZoom);
    const to = injectPathParams(PATHS.USER_INSIGHTS, {
      lat: latitude,
      lon: longitude,
      zoom: expansioZoom,
    });
    history.replace(to);
  };

  useEffect(() => {
    setViewport((currentViewport) => ({
      ...currentViewport,
      latitude: centerCoords ? centerCoords.lat : 0,
      longitude: centerCoords ? centerCoords.lon : 0,
    }));
  }, [centerCoords]);

  useEffect(() => {
    setViewport((currentViewport) => ({
      ...currentViewport,
      zoom,
    }));
  }, [zoom]);

  const getWarningType = (properties: any) => {
    if (properties) {
      return properties.every((item: any) => item.type_event === 1) ? ManholesType.YELLOW : ManholesType.RED;
    }
  };

  const onClickOutsideHandler = useCallback(
    (event: any) => {
      if (isInfoManholeId !== '') {
        setisInfoManholeId('');
      }
    },
    [isInfoManholeId],
  );

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  const handleClick = (id: string | undefined = '', longitude: number, latitude: number) => {
    onSelectMarker(longitude, latitude, viewport.zoom);
    setisInfoManholeId(id);
    const to = injectPathParams(PATHS.USER_INSIGHTS, { lat: latitude, lon: longitude, zoom: viewport.zoom });
    history.replace(to);
  };

  return (
    <Wrapper {...props}>
      <SemiWrapper ref={mapbox} theme={theme}>
        {isGradiented ? <OverlayGradient /> : null}
        <Map
          {...viewport}
          mapboxAccessToken={mapToken}
          ref={mapReference as any}
          onMove={({ viewState }: any) => {
            setViewport(viewState);
            const to = injectPathParams(PATHS.USER_INSIGHTS, {
              lat: viewport.latitude,
              lon: viewport.longitude,
              zoom: viewport.zoom,
            });
            history.replace(to);
          }}
          mapStyle="mapbox://styles/mapbox/light-v11"
          attributionControl={false}
        >
          {clusters &&
            clusters.map((clusterObject) => {
              const { properties, geometry, id: clusterId } = clusterObject;
              const warningType = getWarningType(properties.values);
              const [longitude, latitude] = geometry.coordinates;
              const { cluster: isCluster, count: pointCount } = properties;
              const clusterSize = 50;
              const pointStringLength = `${pointCount}`.length;

              const fontSize = 18 - (pointStringLength - 3) + 'px';
              const isReq = properties?.values?.some(
                (item: { manholeId: string | undefined }) => item.manholeId === isInfoManholeId,
              );

              return (
                <>
                  {isCluster ? (
                    <Marker key={clusterId} latitude={latitude} longitude={longitude}>
                      <Cluster
                        theme={theme}
                        onClick={() => handleClusterClick(latitude, longitude, clusterId)}
                        size={clusterSize}
                        fontSize={fontSize}
                      >
                        {pointCount}
                      </Cluster>
                    </Marker>
                  ) : (
                    <CustomMarker
                      key={properties.values[0].manholeId}
                      latitude={parseFloat(latitude)}
                      longitude={parseFloat(longitude)}
                      setCentre={setCentre}
                      zoom={viewport.zoom}
                      handleClick={() => handleClick(properties.values[0].manholeId, longitude, latitude)}
                      isReq={isInfoManholeId === properties.values[0].manholeId}
                    >
                      <ManholeButton
                        type={type === DeviceListMapType.DASHBOARD ? warningType : ManholesType.GREEN}
                        staticDisplay={staticDisplay}
                        history={history}
                        languageStrings={languageStrings}
                        dataLocationCluster={dataLocationCluster}
                        isReq={isReq}
                        zoom={viewport.zoom}
                        {...properties}
                      />
                    </CustomMarker>
                  )}
                </>
              );
            })}
        </Map>
      </SemiWrapper>
    </Wrapper>
  );
};

export const DeviceListMap = withLoader(undefined, PlaceholderType.MAP)(DeviceListMapPlain);
