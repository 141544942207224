import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageStrings } from '../../app/state/user';
import { withLoader } from '../../features/placeholderComponent/loaderFunctions';
import { LineGraph } from '../graphs';
import { NoDataPlaceholder } from '../nodataPlaceholder/NoDataPlaceholder';
import { PlaceholderType } from '../placeholders/typePlaceholders/placeholdersType';
import { FiltersModal } from './components/filtersModal/FiltersModal';
import { Header } from './components/header/Header';
import { TitleBlock } from './components/titleBlock/TitleBlock';
import { Container, LineWrapper, Wrapper } from './styles';
import { useTheme } from '@cthings.co/styled-components';
import { GraphType } from './types';
import { ThresholdData } from 'components/graphs/components/threshlodMapping/types';

interface DataType {
  data: any[];
  calibrationData: any[];
  thresholdData: ThresholdData[];
  device_id: string;
  header: string;
  id: string;
  sensor: any;
  color: string;
  ts_from: string;
  ts_to: string;
  unit: string;
  invertAxis?: boolean;
}
export interface GraphProps {
  data: DataType;
  header: string;
  filters: any;
  type: GraphType;
  handleChangeCurrentFilters: any;
  setCurrentFilters?: any;
  applyOnClick: () => void;
  className?: string;
  limits: any;
  labels?: { xAxis: string; yAxis: string };
  theme?: any;
  isLoadingInProcess?: boolean;
  hideExportButton?: boolean;
  applyingStatus: number;
  setApplyingStatus: any;
}

const GraphPlain: FC<GraphProps> = ({
  data,
  header,
  filters,
  type,
  handleChangeCurrentFilters,
  setCurrentFilters,
  applyOnClick,
  limits,
  labels,
  theme,
  isLoadingInProcess,
  hideExportButton,
  applyingStatus,
  setApplyingStatus,
  ...props
}) => {
  const defaultTheme = useTheme();
  const { additional16 } = defaultTheme.borderRadius;
  const { additionalShadow2 } = defaultTheme.shadows;

  const languageStrings = useSelector(selectLanguageStrings);

  const [isOpenFiltersModal, setIsOpenFiltersModal] = useState(false);
  const [isApplyButtonActive, setIsApplyButtonActive] = useState(false);
  // const [isOpenDataProcessingModal, setIsOpenDataProcessingModal] = useState(false);
  const [savedData, setSavedData] = useState();

  const handleOpenFilterModal = () => {
    setIsOpenFiltersModal(true);
    setSavedData(filters);
  };

  const handleCloseFiltersModal = () => {
    setIsOpenFiltersModal(false);
    setCurrentFilters(savedData);
  };

  // const openDataProcessingModal = () => {
  //   filters.intervals.default_value.name === 'Max. granularity' && setIsOpenDataProcessingModal(true);
  // };

  // const closeDataProcessingModal = () => {
  //   setIsOpenDataProcessingModal(false);
  // };

  const handleApply = () => {
    applyOnClick();
    setIsOpenFiltersModal(false);
  };

  const setFilters = (filterName: string, value: { tooltip: string; id: string; key: string }) => {
    handleChangeCurrentFilters(filterName, value);

    setIsApplyButtonActive(true);
    setApplyingStatus(0);
  };

  return (
    <Container>
      <TitleBlock
        data={data}
        header={header}
        applyingStatus={applyingStatus}
        type={type}
        filters={filters}
        handleOpenFilterModal={handleOpenFilterModal}
        hideExportButton={hideExportButton}
      />
      <Wrapper
        {...props}
        borderRadius={theme ? theme.borderRadius : additional16}
        shadows={theme ? theme.shadows : additionalShadow2}
      >
        <Header
          applyOnClick={() => {
            setIsApplyButtonActive(false);
            applyOnClick();
          }}
          data={data}
          filters={filters}
          setFilters={setFilters}
          setCurrentFilters={setCurrentFilters}
          graphType={type}
          applyingStatus={applyingStatus}
          isApplyButtonActive={isApplyButtonActive}
          hideExportButton={hideExportButton}
        />
        <LineWrapper>
          {data.data.length && limits.y ? (
            <LineGraph
              graphType={type}
              data={data.data}
              calibrationData={data.calibrationData}
              thresholdData={data.thresholdData}
              color={data.color}
              invertAxis={data.invertAxis}
              limits={limits}
              id={data.id}
              labels={labels}
              unit={data?.unit}
            />
          ) : (
            <NoDataPlaceholder languageStrings={languageStrings} />
          )}
        </LineWrapper>
      </Wrapper>
      <FiltersModal
        isOpenModal={isOpenFiltersModal}
        closeModal={handleCloseFiltersModal}
        handleApply={handleApply}
        filters={filters}
        setCurrentFilters={setCurrentFilters}
        setFilters={setFilters}
        graphType={type}
        applyingStatus={applyingStatus}
        isApplyButtonActive={isApplyButtonActive}
        languageStrings={languageStrings}
      />
      {/* <DataProcessingModal
        isOpenModal={isOpenDataProcessingModal}
        closeModal={closeDataProcessingModal}
        languageStrings={languageStrings}
      /> */}
    </Container>
  );
};

export const Graph = withLoader(undefined, PlaceholderType.GRAPH)(GraphPlain);

Graph.defaultProps = {
  header: 'Temperature',
};
